<template>
  <header class="page-header">
    <el-row :gutter="20" align="middle" type="flex">
      <el-col :span="12">
        <el-button type="default" @click="goBack" class="mr20" v-if="isBackable"><i class="el-icon-back"></i></el-button>
        <h2 class="inline-block">{{title}}</h2>
      </el-col>
    </el-row>
  </header>
</template>

<script>
export default {
  name: 'customHeader',
  props: {
    title: {
      require: true,
      type: String
    },
    isBackable: {
      require: false,
      type: Boolean,
      default: true
    },
    data: {
      type: [],
      default: () => {}
    },
    brndData: {
      type: [],
      default: () => {}
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
