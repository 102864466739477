<template>
  <div>
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button :disabled="isDisabled" type="success" @click="onPressNewProdSupplier('supplierData')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row v-if="!pageStatus.error" v-loading="loading">
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header :title="'Шинэ нийлүүлэгч үүсгэх'"/>
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="supplierData" :rules="supplierRules" ref="supplierData">
          <el-row :gutter="20">
            <el-col :span="20">
              <div class="panel">
              <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр" prop="supplier_monName">
                        <el-input v-model="supplierData.supplier_monName" placeholder="Говь ХХК ..."></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Англи нэр" prop="supplier_engName">
                      <el-input v-model="supplierData.supplier_engName" placeholder="Говь ХХК ..."></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Регистрийн дугаар" prop="supplier_regno">
                        <el-input v-model="supplierData.supplier_regno" placeholder="6183352" :v-on:input="numberValidate()"></el-input>
                      </el-form-item>
                    </el-col>
                      <el-col :span="12">
                        <el-form-item label="Байгууллага">
                          <el-input placeholder="Дижитал аггрегатор" disabled type="text" :value='supplierData.EbarimtSupplier'></el-input>
                        </el-form-item>
                      </el-col>
                      <!-- <el-col :span="6" class="mt20 text-center">
                        <el-form-item label="">
                          <el-checkbox v-model="supplierData.package_connetion_check">Багцын холболттой эсэх</el-checkbox>
                        </el-form-item>
                      </el-col> -->
                      <el-col :span="12">
                        <el-form-item label="Утасны дугаар" prop="phone">
                          <el-input v-model="supplierData.phone" maxlength="12" placeholder="99999999"></el-input>
                        </el-form-item >
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Нэмэлт утасны дугаар">
                          <el-input v-model="supplierData.phone2" maxlength="12" placeholder="99999999"></el-input>
                        </el-form-item >
                      </el-col>
                      <!-- <el-col :span="12">
                        <el-form-item label="Төлөв">
                          <el-switch
                            style="display: block"
                            v-model="supplierData.isActive"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            active-text="Идэвхитэй"
                            inactive-text="Идэвхгүй"
                            class="mt10 text-center">
                          </el-switch>
                        </el-form-item>
                      </el-col> -->
                      <el-col :span="12">
                        <el-form-item label="Хэрэглэгчийн Diamond код">
                          <el-input v-model="supplierData.diamond_code" placeholder="123456"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Имэйл">
                    <el-input v-model="supplierData.supplier_email" type="email" rows="3" placeholder="a.@gmail.com"></el-input>
                  </el-form-item>
                      </el-col>
                        <el-col :span="12">
                      <el-col :span="12">
                        <el-form-item label="Хариуцсан менежер">
                    <el-input v-model="supplierData.in_charge_manager" type="text" rows="3" placeholder="Нэр"></el-input>
                  </el-form-item>
                      </el-col>
                      <el-col :span="24">
                      <el-form-item>
                        <el-checkbox v-model="supplierData.is_tax_paid">НӨАТ төлөгч</el-checkbox>
                      </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="НӨАТ">
                          <el-radio-group v-model="supplierData.ebarimt_type">
                            <el-radio :label="`supplier`">Нийлүүлэгч</el-radio>
                            <el-radio :label="`package_connetion`">Багцын</el-radio>
                            <el-radio :label="`from_us`">Манайхаас</el-radio>
                            <el-radio :label="`NOATgui`">НӨАТ-гүй</el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                      </el-col>
                      <el-col :span="12">
                        <!-- <el-row type="flex" justify="center">
                          <span>Ажиллах цагийн хуваарь</span>
                        </el-row>
                        <el-form-item :label="day.day" v-for="(day, indexDay) in week" :key="indexDay">
                         <el-time-select
                            :disabled="day.is_weekend"
                            size="mini"
                            placeholder="Нээх цаг"
                            v-model="day.start_time"
                            :picker-options="{
                              start: '08:30',
                              step: '00:15',
                              end: '23:59'
                            }">
                          </el-time-select>
                          <el-time-select
                            class="ml5"
                            size="mini"
                            :disabled="day.is_weekend"
                            placeholder="Хаах цаг"
                            v-model="day.end_time"
                            :picker-options="{
                              start: '08:30',
                              step: '00:15',
                              end: '23:59'
                            }">
                          </el-time-select>
                          <el-tooltip class="item" effect="light" content="Амрах өдөр" placement="top">
                            <el-checkbox class="ml10" v-model="day.is_weekend" @change="generateDateTime(day)"></el-checkbox>
                          </el-tooltip>
                        </el-form-item> -->
                      </el-col>
                    </el-row>
                      <el-form-item label="Хаяг">
                    <el-input v-model="supplierData.address" type="textarea" rows="3" placeholder="ХУД 3-р хороо Гоёо"></el-input>
                  </el-form-item>
                  <el-form-item label="Байршил">
                    <el-input v-model="supplierData.location" disabled></el-input>
                  </el-form-item>
                  <div class="" style="width: 100%; height: 400px; display:block">
                    <gmap-map
                      :center="googleMap.center"
                      :zoom="12"
                      :options="googleMap.options"
                      :draggable="false"
                      style="width:100%;  height: 100%; margin-top: 5px;"
                    >
                    <gmap-marker
                      :position="googleMap.location"
                      :clickable="true"
                       @position_changed="changeLocation($event)"
                      :draggable="true"/>
                    </gmap-map>
                  </div>
                </div>
                </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="20" class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" :disabled="isDisabled" @click="onPressNewProdSupplier('supplierData')
                ">Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/supplier">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import customHeader from '../../../components/common/customHeader'
import services from '../../../helpers/services'
export default {
  components: {
    customHeader: customHeader
  },

  data () {
    return {
      isDisabled: false,
      loading: false,
      confirmDeleteSupplierDialog: false,
      confirmLeaveForm: false,
      brandList: [],
      supplierBrand: [],
      errorArray: [],
      pageStatus: {
        error: false,
        message: ''
      },
      supplierData: {
        is_tax_paid: false,
        isActive: true,
        diamond_code: '',
        package_connetion_check: false,
        ebarimt_type: '',
        brands: [],
        supplier_monName: '',
        supplier_engName: '',
        supplier_regno: '',
        EbarimtSupplier: '',
        phone: '',
        phone2: '',
        address: '',
        location: '47.9174, 106.9137',
        supplier_id: ''
      },

      googleMap: {
        center: {
          lat: 47.9174,
          lng: 106.9137
        },

        location: {
          lat: 47.9174,
          lng: 106.9137
        },

        markerOptions: {
          size: { width: 100, height: 90, f: 'px', b: 'px' },
          scaledSize: { width: 45, height: 45, f: 'px', b: 'px' }
        },

        options: {
          scrollwheel: true,
          mapTypeControl: true,
          streetViewControl: false,
          minZoom: 4,
          maxZoom: 20,
          gestureHandling: 'greedy'
        }
      },

      // week: [
      //   {
      //     day: 'Даваа',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Мягмар',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Лхагва',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Пүрэв',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Баасан',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Бямба',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Ням',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   }
      // ],

      supplierRules: {
        supplier_monName: [
          { required: true, message: 'Байгууллагын монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        supplier_engName: [
          { required: true, message: 'Байгууллагын англи нэрээ оруулна уу', trigger: 'blur' }
        ],
        supplier_regno: [
          { required: true, message: 'Байгууллагын регистрийн дугаараа бөглөнө үү', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Утасны дугаараа оруулна уу', trigger: 'blur' },
          { min: 6, max: 12, message: 'Утасны дугаараа бүрэн оруулна уу', trigger: 'blur' }
        ],
        address: [
          { required: true, message: 'Хаягаа оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },

  created () {
    if (this.$route.params.supplier_id) {
      this.getOneSupplier(this.$route.params.supplier_id)
    }
  },

  methods: {
    generateDateTime (day) {
      if (day.is_weekend === true) {
        day.start_time = null
        day.end_time = null
      }
    },

    numberValidate () {
      if (this.supplierData.supplier_regno.length <= 10) {
        this.checkEbarimt()
      }
    },

    checkEbarimt () {
      services.checkEbarimt(this.supplierData.supplier_regno).then(response => {
        if (response.found === true) {
          this.supplierData.EbarimtSupplier = response.name
        }
      })
    },

    changeLocation (event) {
      this.supplierData.location = event.lat() + ', ' + event.lng()
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },

    onPressNewProdSupplier (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.isDisabled = true
          if (this.supplierData.supplier_id !== '') {
            services.updateSupplier(this.supplierData).then(data => {
              if (data.status === 'success') {
                this.alertReporter('Амжилттай', data.message, 'success')
                this.loading = false
                this.$router.push({ name: 'supplier' })
              } else if (data.status === 'error') {
                this.alertReporter('Алдаа', data.message, 'error')
                this.loading = false
              }
            })
          } else {
            // this.supplierData.work_time = this.generateWorkTime()
            services.addSupplier(this.supplierData).then(data => {
              if (data.status === 'success') {
                this.alertReporter('Амжилттай', data.message, 'success')
                this.isDisabled = false
                this.loading = false
                this.$router.push({ name: 'supplier' })
              } else if (data.status === 'error') {
                this.alertReporter('Алдаа', data.message, 'error')
                this.loading = false
              }
            })
          }
        } else {
          console.log('error submit!!!!!')
          return false
        }
      })
    },

    // generateWorkTime () {
    //   const workingTime = {}
    //   this.week.forEach(day => {
    //     if (day.day === 'Даваа') {
    //       workingTime.monday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Мягмар') {
    //       workingTime.tuesday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Лхагва') {
    //       workingTime.wednesday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Пүрэв') {
    //       workingTime.thursday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Баасан') {
    //       workingTime.friday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Бямба') {
    //       workingTime.saturday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Ням') {
    //       workingTime.sunday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     }
    //   })
    //   return workingTime
    // },

    deleteSupplier (id) {
      this.confirmDeleteSupplierDialog = false
      this.loading = true
      services.deleteSupplier({ supplier_id: id }).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', `${id} дугаартай нийлүүлэгч устлаа`, 'success')
          this.loading = false
          this.$router.push({ name: 'supplier' })
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', `${id} дугаартай нийлүүлэгчийг устгахад алдаа гарлаа`, 'error')
          this.loading = false
        }
      })
    },

    getOneSupplier (id) {
      services.getOneSupplier(id).then(response => {
        if (response.status === 'success') {
          this.supplierData = response.data
          const location = this.supplierData.location.split(',')
          this.googleMap.location.lat = parseFloat(location[0])
          this.googleMap.location.lng = parseFloat(location[1])
          this.getSubpplierBrands(this.supplierData.supplier_id)
          this.loading = false
        } else if (response.status === 'error') {
          this.pageStatus.error = true
          this.pageStatus.message = response.message
        }
      })
    }
  }

}
</script>

<style></style>
